import initialCrudState from "@/store/templates/crud/state";
import { createEntityPlaceholders } from "@/store/helpers";

const initialModel = () => ({
  id: null,
  original: null,
  // TYPE
  type: "COMPANY",
  company: null,
  filters: [],
  // CATEGORIES
  languages: [],
  filterCategories: [],
  // GEOGRAPHY
  country: 1,
  isEntireCountry: true,
  provinces: [],
  cities: [],
  includedPolygons: [],
  excludedPolygons: [],
  includedCircles: [],
  excludedCircles: [],
  // ABOUT
  name: null,
  isActive: true,
  apiKey: null,
  priceEuro: null,
  isAutoclaim: false,
  isAutoDelivery: false,
  isPriority: false,
  maxWeeklyLeads: null,
  remark: null,
  isPaused: null,
  pausedFrom: null,
  pausedTill: null,
  deliveryMethods: [],
  logs: [],
  parentFilters: []
});

export default () => ({
  ...initialCrudState(initialModel),
  ...createEntityPlaceholders([
    "filter",
    "city",
    "province",
    "country",
    "company",
    "category",
    "question",
    "questionOption",
    "localization",
    "log"
  ]),
  alwaysVisibleQuestionIds: [],
  categoryQuestionIds: {},
  selectedShape: null,
  isReseller: false,
  companyFilterComment: null
});
